<template>
  <div>
    <v-container style="min-height: 100vh">
      <v-row class="pa-0 pt-5 pb-5">
        <v-col  class="hidden-sm-and-up" cols="12" sm="4">
          <v-text-field
            outlined
            clearable
            dense
            hide-details
            v-model="titleSearch"
            label="Search for an event"
            name="name"
            @change="changeSearchValue('search')"
            v-on:keyup.enter="eventPageSearch()"
          />
        </v-col>

        <v-col cols="12" class="hidden-xs-only" sm="4">
          <v-text-field clearable dense outlined hide-details name="search" id="search" v-model="titleSearch" placeholder="Looking for" @change="changeSearchValue('search')" v-on:keyup.enter="eventPageSearch()" />
        </v-col>

        <v-col cols="12" class="hidden-xs-only"  sm="4">
          <v-text-field dense clearable outlined hide-details name="venue" id="venue" v-model="venueSearch" placeholder="Venue name" @change="changeSearchValue('venue')" v-on:keyup.enter="eventPageSearch()" />
        </v-col>

        <v-col class="hidden-xs-only" cols="4">
          <v-menu
            ref="mfilterEventDate"
            v-model="mfilterEventDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <div>
                <v-text-field
                  outlined
                  dense
                  hide-details
                  :value="parseEventDateFormat"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  placeholder="When"
                />
              </div>
            </template>
            <v-date-picker
              :min="today"
              range
              v-model="query.filterEventDate"
              @change="handeleEventDate"
            >
              <v-btn 
                  text color="primary" @click="mfilterEventDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearEventDate()">Clear
                </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="mt-10 mt-sm-n4" justify="end">
        <v-col cols="12" sm="7" md="5" class="py-0">
          <v-row>
            <v-col>
              <v-select
                v-model="query.weekdays"
                dense
                outlined
                :items="weekdays"
                :item-text="'text'"
                :item-value="'value'"
                label="Weekdays"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="query.genre"
                dense
                outlined
                :items="genres"
                item-text="name"
                item-value="_id"
                label="Any category"
              />
            </v-col>
            <v-col>
              <v-btn
                block
                large
                color="primary"
                class="white--text"
                @click="eventPageSearch()"
                >Search</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template v-if="loaded">
        <template v-if="allEventsData.count">
          <section class="mb-8 mb-sm-8 mb-md-12 mt-4 mt-sm-0" v-for="item in eventKeys" :key="item">
            <div
              v-if="allEventsData[item].length"
              class="text-h6 text-sm-h5 text-decoration-underline primary-text-decoration mb-4 mb-sm-4 mb-md-6"
            >
              {{ genreMap[item] }}
            </div>

            <v-slide-group v-if="allEventsData[item].length" show-arrows="desktop" class="rounded py-4 v-sheet--outlined" v-model="model">
              <v-slide-item v-for="event in allEventsData[item]" :key="event._id">
                <v-card
                  class="ma-2"
                  :width="getCardWidth()"
                  :to="{ name: 'visitor.event', params: { id: event.slug } }"
                >
                  <v-layout justify="end" v-if="event.status === 'cancelled'">
                    <v-sheet
                      class="mt-4"
                      style='z-index:1;position:absolute;'
                    >
                      <v-chip label color="red" text-color="white" class="rounded-0">
                        CANCELLED
                      </v-chip>
                    </v-sheet>
                  </v-layout>
                  <v-img :aspect-ratio="16/9" :src="$get400Image(event.image, event.imageUserId, event._id)"></v-img>
                  <v-list-item two-line>
                    <v-list-item-action class="mr-4 primary--text d-grid text-bold">
                      <div class="text-center">{{ event.startsAt | day }}</div>
                      <div>{{ event.startsAt | month }}</div>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ event.title }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ event.subtitle }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </section>

        </template>

        <div class="mt-4" v-else>
          <v-card flat>
            <v-card-title class="justify-center primary--text ">
              We're sorry, but there were no results for your search.
            </v-card-title>

            <v-card-subtitle class="text-center">
              Please try again using an alternative search term.
            </v-card-subtitle>

            <!-- <v-card-text>
                <v-row justify="center">
                  <v-col cols="8" sm="4" md="3">
                    <v-text-field dense outlined style="width:400px" placeholder="johndoe@gmail.com"></v-text-field>
                  </v-col>
                  <v-col cols="4" sm="2" md="1">
                    <v-btn block color="primary">Notify</v-btn>
                  </v-col>
                </v-row>
              </v-card-text> -->

            <v-img class="mx-auto my-4" width="300" height="300" src="@/assets/images/no-records.svg" />
          </v-card>
        </div>
      </template>

      <v-row v-else>
        <v-col cols="12" sm="4" v-for="item in 9" :key="item">
          <v-skeleton-loader class="mx-auto" max-width="300" type="card"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { HomeService } from '@services';

export default {
  data() {
    return {
      loaded: false,
      isSeeMoreClicked: false,
      allEventsData: [],
      eventKeys: [],
      events: [],
      genreMap: {},
      genres: [],
      titleSearch: '',
      venueSearch: '',
      model: null,
      today: new Date().toISOString().substr(0, 10),
      datePicker: false,
      query: {
        weekdays: '',
        search: '',
        venue: '',
        date: '',
        genre: '',
        isSeeMore: '',
        eventtype: 'All',
        eventStarts: '',
        eventEnds: '',
        filterEventDate: [],
      },
      weekdays: [
        { text: 'All', value: '' },
        { text: 'Today', value: 'today' },
        { text: 'Tomorrow', value: 'tommorow' },
        { text: 'Weekdays', value: 'weekdays' },
        { text: 'This Weekend', value: 'thisweekend' },
        { text: 'Next Month', value: 'nextmonth' },
        { text: 'Next Year', value: 'nextyear' },
      ],
      searchMappping: {
        venue: 'venueSearch',
        search: 'titleSearch'
      },
      mfilterEventDate: false,
      isValidEventDate: true,
    };
  },
  beforeMount() {
    this.getGenres();
  },
  computed: {
    parseEventDateFormat() {
      return this.$parseTransDateFormat(this.query.filterEventDate);
    }
  },
  created() {
    this.getEvents();
    if (this.$route.query.utm_source) {
      localStorage.setItem('utm_source', JSON.stringify({ 
        utm_source: this.$route.query.utm_source,
        utm_medium: this.$route.query.utm_medium,
        utm_campaign: this.$route.query.utm_campaign,
        utm_eventid: this.$route.params.id,
      }))
    }
  },
  methods: {
    async getGenres() {
      const data = await HomeService.getEventGenres();
      if (data) {
        this.genres.push({ _id: '', name: 'All' });
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          this.genreMap[element._id] = element.name;
          this.genres.push(element);
        }
        this.genreMap['recentEvents'] = 'Upcoming events';
      }
    },
    async getEvents(options) {
      this.loaded = false;
      //let queryString = this.originalQueryParams(this.$route.query);
      if(this.$route.query.se !== undefined) {
        this.query.search  = this.$route.query.se;
        this.titleSearch = this.$route.query.se;
      }
      if(this.$route.query.da !== undefined) {
        const dates = this.$route.query.da.split(",")
        this.query.eventStarts = dates[0];
        this.query.eventEnds = dates[1];
        this.query.filterEventDate = dates;
        this.query.date  = this.$route.query.da;
        this.query.date = this.$route.query.da;
      }
      if(this.$route.query.ve !== undefined) {
        this.query.venue  = this.$route.query.ve;
        this.venueSearch = this.$route.query.ve;
      }
      if(this.$route.query.we !== undefined) {
        this.query.weekdays = this.$route.query.we;
      }
      if(this.$route.query.ge !== undefined) {
        this.query.genre = this.$route.query.ge;
      }
      this.query.isSeeMore = true;
      this.allEventsData = await HomeService.getUpcomingEvents(this.query);
      this.eventKeys = Object.keys(this.allEventsData);
      this.loaded = true;
    },
    getCardWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return 280;
      } else if (this.$vuetify.breakpoint.sm) {
        return 300;
      } else if (this.$vuetify.breakpoint.md) {
        return 380;
      } else if (this.$vuetify.breakpoint.xl) {
        return 530;
      } else {
        return 335;
      }
    },
    updateQueryParams(field, value) {
      this.$router.replace({name: this.$router.name, query: {...this.$route.query, [field]: value || undefined}})
    },
    eventPageSearch() {
      const filters = this.query;
      const queryParams = this.shortQueryParams(filters);
      this.$next('/event-search'+queryParams);
      this.getEvents();
    },
    clearSearch(fieldName) {
      this.updateQueryParams(fieldName, "");
      if(fieldName === 'da') {
        this.query.date = undefined;
        this.getEvents();
      }
    },
    clearEventDate() {
      this.$refs.mfilterEventDate.save([]);
      this.query['eventStarts'] = '';
      this.query['eventEnds'] = '';
      this.query['date'] = '';
      this.query.filterEventDate = [];
    },
    changeSearchValue(fieldName) {
      let fieldValue = this.searchMappping[fieldName];
      this.query[fieldName] = this[fieldValue];
    },
    handeleEventDate(val) {
      this.isValidEventDate = this.$validateDateRange(val);
      if(this.isValidEventDate) {
        this.query.filterEventDate = val;
      }
    }
  },
};
</script>